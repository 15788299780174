<template>
  <div>
    <highcharts :options="chartOptions" ref="lineCharts" :constructor-type="'chart'"></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
HighchartsMore(Highcharts)

export default {
  name: 'PointChart',
  components: {
    highcharts: Chart
  },
  props: ['options', 'title'],
  data () {
    return {
      chartOptions: Highcharts.merge(this.options, {
        chart: {
          polar: true,
          type: 'line',
          height: 550,
          marginRight: 2,
          marginLeft: 2,
          spacingLeft: 2,
          spacingRight: 2,
          events: {
            load: function () {
              // let categories = this.xAxis[0].categories
              let seriesData = this.series[0].data
              let newCategories = []
              for (let i = 0; i < seriesData.length; i++) {
                newCategories.push(`<b style="color: darkblue; text-align: center;"> ${seriesData[i].y}</b> <br> ${seriesData[i].name}`)
              }
              this.xAxis[0].setCategories(newCategories)
            }
          }
        },
        title: {
          text: ''
        },
        pane: {
          size: '74%'
        },
        xAxis: {
          type: 'category',
          lineWidth: 0,
          gridLineWidth: 1,
          labels: {
            style: {
              fontSize: '10px',
              color: 'black'
            }
          },
          tickmarkPlacement: 'on'
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 1,
          gridLineWidth: 1,
          showLastLabel: false,
          tickInterval: 20,
          max: 120,
          min: 0
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '{point.key}<br>',
          pointFormat: 'SD : {point.sd}'
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            fillOpacity: 0.2,
            coloredBorder: true,
            pointPlacement: 'on',
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '11px'
              },
              allowOverlap: false
            }

          }
        },
        credits: {
          enabled: false
        },
        colors: ['#2f7ed8', '#FB63A1'],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 150
            },
            chartOptions: {
              legend: {
                align: 'bottom',
                verticalAlign: 'bottom'
              },
              pane: {
                size: '50%'
              }
            }
          }]
        }
      })
    }
  }
}

</script>

<style >
  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #a49da5;
  }
  .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #a49da5;
  }
</style>
