<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div >
    <div v-if="isExternalVisualisationUrl">
      <data-visualisation></data-visualisation>
    </div>
    <h1 v-else>
      <dashboard-content></dashboard-content>
    </h1>
  </div>
</template>

<script>
import DashboardContent from './DashboardContent'
import DataVisualisation from './DataVisualisation'

export default {
  components: {
    DashboardContent,
    DataVisualisation
  },
  name: 'Dashboard',
  data () {
    return {
      loading: false
    }
  },
  methods: {
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    isExternalVisualisationUrl () {
      return this.$store.state.home.user.externalVisualisationUrl
    }
  },
  mounted () {
    if (this.$store.state.home.currentPage.trim() === '' && window.$cookies.get('standard_current_page') && window.$cookies.get('standard_current_page').trim() !== '') {
      if (window.$cookies.get('standard_current_page') === 'results') {
        this.$router.push({ name: 'results' })
      } else if (window.$cookies.get('standard_current_page') === 'bulkdownload' && this.$store.state.home.user.showExportButton) {
        this.$router.push({ name: 'bulkDownload' })
      } else if (window.$cookies.get('standard_current_page') === 'groupReports') {
        this.$router.push({ name: 'groupReports' })
      } else if (window.$cookies.get('standard_current_page') === 'learningResources') {
        this.$router.push({ name: 'learningMaterials' })
      } else {
        this.getDataFromApi()
        this.$store.dispatch('home/setCurrentPage', 'Dashboard')
        this.$cookies.set('standard_current_page', 'Dashboard')
      }
    } else {
      this.getDataFromApi()
      this.$store.dispatch('home/setCurrentPage', 'Dashboard')
      this.$cookies.set('standard_current_page', 'Dashboard')
    }

    let pageTitle = ''
    if (this.$cookies.get('standard_dashboard_assessment_name') && this.$cookies.get('standard_dashboard_assessment_name').trim() !== '') {
      pageTitle = this.$cookies.get('standard_dashboard_assessment_name')
    }
    document.title = pageTitle
  }
}
</script>
<style scoped>

</style>
