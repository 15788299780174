<template>
  <div class="col-md-12 mt-2 mb-2">
    <div class="tableau-container">
      <tableau-viz
        ref="vizContainer"
        id="tableauViz"
        :src="tableauUrl"
        :device="device"
        hide-tabs
        :token=this.tableauToken
        toolbar="hidden"
        width="100%"
      >
        <viz-filter field="Org" value="MTDC" />
      </tableau-viz>
    </div>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="loadingError" class="error">Error loading Tableau visualization</div>
  </div>
</template>

<script>
import { dashboardService } from '../_services' // Adjust to your service path

export default {
  name: 'DataVisualisation',
  data () {
    return {
      tableauUrl: 'https://prod-apsoutheast-a.online.tableau.com/t/tableaubigpsb/views/ent_mtdc/MainDashboard?:toolbar=no',
      tableauToken: null,
      loading: false,
      loadingError: false,
      device: 'desktop'
    }
  },
  mounted () {
    this.addTableauScript()
    this.fetchTableauToken()
  },
  methods: {
    addTableauScript () {
      const script = document.createElement('script')
      script.type = 'module'
      script.src = 'https://online.tableau.com/javascripts/api/tableau.embedding.3.1.0.min.js'
      script.async = true
      document.head.appendChild(script)
    },
    async fetchTableauToken () {
      this.loading = true
      try {
        const response = await dashboardService.getTableauToken()
        if (response && response.token) {
          this.tableauToken = response.token
        } else {
          throw new Error('Failed to retrieve Tableau token')
        }
      } catch (error) {
        this.loadingError = true
        /* eslint-disable no-console */
        console.error('Error fetching Tableau token:', error)
        /* eslint-enable no-console */
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.tableau-container {
  width: 100%;
  height: 800px
}

.loading {
  text-align: center;
  font-size: 1.5rem
}

.error {
  text-align: center;
  color: red;
  font-size: 1.5rem
}
</style>
